import CheckboxAlt from "components/CheckboxAlt";
import { DateTime } from "luxon";
import { ClassSearchContext } from "pages/newClassSearch/classSearchContext";
import React, { useContext } from "react";

import { InterSemesterUI } from "./shared";

interface StartDateListProps {
  semesterUIList: InterSemesterUI[];
  setSemesterUIList: React.Dispatch<React.SetStateAction<InterSemesterUI[]>>;
}

const InterStartDateList: React.FC<StartDateListProps> = ({
  semesterUIList,
  setSemesterUIList,
}) => {
  const { dispatch } = useContext(ClassSearchContext);

  // Find the selected semester
  const selectedSemester = semesterUIList.find((item) => item.isSelected);

  if (!selectedSemester) {
    return <ul />;
  }

  // Sort class formats so that "Online" appears at the bottom
  const sortedClassFormats = selectedSemester.classFormats.sort((a, b) => {
    if (a.name === "Online") return 1;
    if (b.name === "Online") return -1;
    return 0;
  });

  return (
    <ul>
      {sortedClassFormats.map((classFormat) => (
        <li key={classFormat.name} className="!pt-2 ps-4">
          {/* Class Format Title */}
          <h4 className="text-sm font-semibold">
            {classFormat.name === "Onsite/Virtual"
              ? "Onsite/Virtual Synchronous"
              : classFormat.name}
          </h4>
          {classFormat.months
            // Sort months by their chronological order
            .sort((a, b) => {
              const monthOrder = [
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "October",
                "November",
                "December",
              ];
              const monthA = DateTime.fromISO(a.startDates[0]?.startDate || "").toFormat("MMMM");
              const monthB = DateTime.fromISO(b.startDates[0]?.startDate || "").toFormat("MMMM");
              return monthOrder.indexOf(monthA) - monthOrder.indexOf(monthB);
            })
            .map((month) => {
              const firstStartDate = month.startDates[0];

              if (!firstStartDate) return null;

              return (
                <div key={month.name} className="ml-2">
                  {/* Month Name */}
                  <div className="flex gap-3 rounded py-2">
                    <div>
                      <CheckboxAlt
                        id={`checkbox-${classFormat.name}-${month.name}`}
                        value={month.isSelected}
                        onChange={() => {
                          setSemesterUIList((prev) => {
                            const newSemesterUIList = prev.map((semester) =>
                              semester.semesterCode === selectedSemester.semesterCode
                                ? {
                                    ...semester,
                                    classFormats: semester.classFormats.map((format) =>
                                      format.name === classFormat.name
                                        ? {
                                            ...format,
                                            months: format.months.map((m) =>
                                              m.name === month.name
                                                ? {
                                                    ...m,
                                                    isSelected: !m.isSelected,
                                                    startDates: m.startDates.map((startDate) => ({
                                                      ...startDate,
                                                      isSelected: !m.isSelected,
                                                    })),
                                                  }
                                                : m
                                            ),
                                          }
                                        : format
                                    ),
                                  }
                                : semester
                            );

                            // Deduplicate and group unique semester-month combinations
                            const updatedStartDateList = newSemesterUIList
                              .flatMap((semester) =>
                                semester.classFormats.flatMap((format) =>
                                  format.months
                                    .filter((m) => m.isSelected)
                                    .flatMap((m) =>
                                      m.startDates.filter((startDate) => startDate.isSelected)
                                    )
                                )
                              )
                              .map((startDate) => startDate.startDate);
                            dispatch({
                              type: "UPDATE_CLASS_START_DATE_LIST",
                              payload: {
                                classStartDateList: updatedStartDateList,
                              },
                            });

                            return newSemesterUIList;
                          });
                        }}
                      />
                    </div>
                    <p className="select-none font-bold">
                      {classFormat.name === "Online"
                        ? DateTime.fromISO(firstStartDate.startDate).toFormat("MMMM d")
                        : DateTime.fromISO(firstStartDate.startDate).toFormat("MMMM")}
                    </p>
                  </div>
                </div>
              );
            })}
        </li>
      ))}
    </ul>
  );
};

export default InterStartDateList;
