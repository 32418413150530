import { ClassSearchContext } from "pages/newClassSearch/classSearchContext";
import React, { useContext } from "react";

import { InterSemesterUI } from "./shared";

interface StartDateOptionsProps {
  semesterUIList: InterSemesterUI[];
  setSemesterUIList: React.Dispatch<React.SetStateAction<InterSemesterUI[]>>;
}

const monthNameMap: Record<string, string> = {
  "01": "January",
  "02": "February",
  "03": "March",
  "04": "April",
  "05": "May",
  "06": "June",
  "07": "July",
  "08": "August",
  "09": "September",
  "10": "October",
  "11": "November",
  "12": "December",
};

const InterStartDateOptions: React.FC<StartDateOptionsProps> = ({
  semesterUIList,
  setSemesterUIList,
}) => {
  const { dispatch } = useContext(ClassSearchContext);

  // Flat map for the start date options with all necessary information
  const startDateOptions = semesterUIList.flatMap((semesterUI) =>
    semesterUI.classFormats.flatMap((classFormat) =>
      classFormat.months
        .filter((month) => month.isSelected)
        .flatMap((month) => {
          const dayNumber = month.startDates[0].startDate.split("-")[2];
          if (classFormat.name === "Online") {
            return `${semesterUI.semesterName.split("-")[1]} - ${
              monthNameMap[month.name]
            } ${dayNumber} - ${classFormat.name}`;
          }
          return `${semesterUI.semesterName.split("-")[1]} - ${
            monthNameMap[month.name]
          } - ${classFormat.name}`;
        })
    )
  );

  // Handle removing a start date option
  const handleRemoveMonthOption = (item: string) => {
    const [, trimmedSemesterName, monthName, , classFormatName] =
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      /(\w+\s\d+)\s-\s(\w+)(\s\d+)?\s-\s([\w/]+)/.exec(item)!;

    setSemesterUIList((prev) => {
      const newSemesterUIList = prev.map((semester) =>
        semester.semesterName.split("-")[1] === trimmedSemesterName
          ? {
              ...semester,
              classFormats: semester.classFormats.map((classFormat) =>
                classFormat.name === classFormatName
                  ? {
                      ...classFormat,
                      months: classFormat.months.map((month) =>
                        monthNameMap[month.name] === monthName
                          ? { ...month, isSelected: false }
                          : month
                      ),
                    }
                  : classFormat
              ),
            }
          : semester
      );

      const updatedStartDateList = Array.from(
        new Set(
          newSemesterUIList.flatMap((semester) =>
            semester.classFormats.flatMap((classFormat) =>
              classFormat.months
                .filter((month) => month.isSelected) // Only include selected months
                .flatMap((month) =>
                  month.startDates.map((startDate) => startDate.startDate)
                )
            )
          )
        )
      );

      // Dispatch the updated start dates to the context
      dispatch({
        type: "UPDATE_CLASS_START_DATE_LIST",
        payload: { classStartDateList: updatedStartDateList },
      });

      return newSemesterUIList;
    });
  };

  return (
    <>
      {startDateOptions.length === 0 ? (
        <div key="sd">
          <span className="m-[3px] !py-[4px] px-[2px]">Select...</span>
        </div>
      ) : (
        startDateOptions.map((item, idx) => (
          <p
            key={idx}
            className="m-[2px] inline-block select-none rounded-sm bg-du-light-gray px-[6px] py-[3px]"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {item}
            <button
              className="ml-1 font-bold capitalize"
              onClick={() => {
                handleRemoveMonthOption(item);
              }}
            >
              &times;
            </button>
          </p>
        ))
      )}
    </>
  );
};

export default InterStartDateOptions;
