import Header from "components/Header";
import OutLink from "components/OutLink";
import PageWrapper from "components/PageWrapper";
import Paragraph from "components/Paragraph";
import Tile from "components/Tile";
import useFetch from "hooks/useFetch";
import React, { useEffect, useState } from "react";
import { angularize } from "react-in-angularjs";
import { StudentIDResponse } from "types/classSearchService";
import { sendEvents } from "utils/googleTagManager";

interface StudentSupportProps {
  dutyStationAdvisorEmail?: string;
}

const StudentSupport: React.FC<StudentSupportProps> = ({
  dutyStationAdvisorEmail,
}) => {
  const { data: StudentIDResponse } = useFetch<StudentIDResponse>({
    service: "classSearchService",
    method: "getCampusCode",
  });
  const [campus, setCampus] = useState<
    "ADLPH" | "EUROP" | "ASIA" | undefined
  >();

  useEffect(() => {
    setCampus(StudentIDResponse?.Student_ID?.CAMPUS);
  }, [StudentIDResponse]);

  useEffect(() => {
    sendEvents();
  }, []);

  const getLinks = (advisorEmail?: string) => {
    if (!advisorEmail) return null;
    let locationsUrl = "";
    if (campus === "EUROP") {
      locationsUrl = "https://europe.umgc.edu/locations";
    } else if (campus === "ASIA") {
      locationsUrl = "https://asia.umgc.edu/locations";
    }
    const links = (
      <>
        <Paragraph className="mb-4">
          <OutLink href={`mailto:${advisorEmail}`}>{advisorEmail}</OutLink>
        </Paragraph>
        <Paragraph className="mb-4">
          {locationsUrl === "" ? (
            <OutLink href="tel:8556558682">855-655-8682</OutLink>
          ) : (
            <OutLink href={locationsUrl}>Go To Locations</OutLink>
          )}
        </Paragraph>
      </>
    );
    return links;
  };

  return (
    <PageWrapper className="mx-0 grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3">
      <Tile className="sm:col-span-2 md:col-span-3" ariaLabel="Student Support">
        <Header className="mb-4">Student Support Center</Header>
        <Paragraph className="mb-4">
          A list of services and resources available to help you move forward
          toward your personal, career, and educational objectives.
        </Paragraph>
      </Tile>
      <Tile className="md:col-span-2" ariaLabel="Writing Center">
        <div className="mb-2 flex items-center gap-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 576 512"
            className="h-7 w-7 min-w-[28px]"
          >
            <path
              className="fill-du-black stroke-du-black"
              d="m180 184h24c6.629 0 12-5.373 12-12v-24c0-6.627-5.371-12-12-12h-24c-6.627 0-12 5.373-12 12v24c0 6.6 5.4 12 12 12zm96 96h24c6.629 0 12-5.373 12-12v-24c0-6.627-5.371-12-12-12h-24c-6.627 0-12 5.373-12 12v24c0 6.6 5.4 12 12 12zm-96 0h24c6.629 0 12-5.373 12-12v-24c0-6.627-5.371-12-12-12h-24c-6.627 0-12 5.373-12 12v24c0 6.6 5.4 12 12 12zm192-96h24c6.629 0 12-5.373 12-12v-24c0-6.627-5.371-12-12-12h-24c-6.627 0-12 5.373-12 12v24c0 6.6 5.4 12 12 12zm-96 0h24c6.629 0 12-5.373 12-12v-24c0-6.627-5.371-12-12-12h-24c-6.627 0-12 5.373-12 12v24c0 6.6 5.4 12 12 12zm-168 144h-24c-6.627 0-12 5.373-12 12v24c0 6.627 5.373 12 12 12h24c6.629 0 12-5.373 12-12v-24c0-6.6-5.4-12-12-12zm264-48h24c6.629 0 12-5.373 12-12v-24c0-6.627-5.371-12-12-12h-24c-6.627 0-12 5.373-12 12v24c0 6.6 5.4 12 12 12zm-264-144h-24c-6.627 0-12 5.373-12 12v24c0 6.627 5.373 12 12 12h24c6.629 0 12-5.373 12-12v-24c0-6.6-5.4-12-12-12zm384 96h-24c-6.627 0-12 5.373-12 12v24c0 6.627 5.373 12 12 12h24c6.629 0 12-5.373 12-12v-24c0-6.6-5.4-12-12-12zm-384 0h-24c-6.627 0-12 5.373-12 12v24c0 6.627 5.373 12 12 12h24c6.629 0 12-5.373 12-12v-24c0-6.6-5.4-12-12-12zm404-168h-448c-35.35 0-64 28.65-64 64v256c0 35.35 28.65 64 64 64h448c35.35 0 64-28.65 64-64v-256c0-35.35-28.7-64-64-64zm32 320c0 17.64-14.36 32-32 32h-448c-17.64 0-32-14.36-32-32v-256c0-17.64 14.36-32 32-32h448c17.64 0 32 14.36 32 32v256zm-52-248h-24c-6.627 0-12 5.373-12 12v24c0 6.627 5.373 12 12 12h24c6.629 0 12-5.373 12-12v-24c0-6.6-5.4-12-12-12zm-92 200h-224c-8.8 0-16 7.2-16 16s7.156 16 16 16h224c8.844 0 16-7.156 16-16s-7.2-16-16-16zm92-8h-24c-6.627 0-12 5.373-12 12v24c0 6.627 5.373 12 12 12h24c6.629 0 12-5.373 12-12v-24c0-6.6-5.4-12-12-12z"
            ></path>
          </svg>
          <Header className="!text-2xl">Writing Center</Header>
        </div>
        <Paragraph className="mb-4">
          Learn about our complementary writing-related services available
          through the UMGC writing center.
        </Paragraph>
        <OutLink href="https://www.umgc.edu/current-students/learning-resources/writing-center">
          Go To Writing Center
        </OutLink>
      </Tile>
      <Tile ariaLabel="Tutoring Services">
        <div className="mb-2 flex items-center gap-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 576 512"
            className="h-7 w-7 min-w-[28px]"
          >
            <path
              className="fill-du-black stroke-du-black"
              d="M248 56C248 86.93 222.9 112 192 112C161.1 112 136 86.93 136 56C136 25.07 161.1 0 192 0C222.9 0 248 25.07 248 56zM192 32C178.7 32 168 42.75 168 56C168 69.25 178.7 80 192 80C205.3 80 216 69.25 216 56C216 42.75 205.3 32 192 32zM144 512C135.2 512 128 504.8 128 496V202.2L78.12 295.5C73.95 303.3 64.26 306.3 56.46 302.1C48.67 297.9 45.73 288.3 49.89 280.5L108.7 170.3C122.6 144.3 149.8 128 179.3 128H320V48C320 21.49 341.5 0 368 0H592C618.5 0 640 21.49 640 48V272C640 298.5 618.5 320 592 320H368C341.5 320 320 298.5 320 272V192H352V272C352 280.8 359.2 288 368 288H592C600.8 288 608 280.8 608 272V48C608 39.16 600.8 32 592 32H368C359.2 32 352 39.16 352 48V128H400C408.8 128 416 135.2 416 144C416 152.8 408.8 160 400 160H256V496C256 504.8 248.8 512 240 512C231.2 512 224 504.8 224 496V352H160V496C160 504.8 152.8 512 144 512H144zM179.3 160C172.5 160 165.1 161.4 160 164V320H224V160H179.3z"
            ></path>
          </svg>
          <Header className="!text-2xl">Tutoring Services</Header>
        </div>
        <div className="space-y-2">
          <div>
            <Paragraph>
              Find out about our no-cost tutoring services to help you achieve
              your academic and career goals.
            </Paragraph>
            <OutLink href="https://www.umgc.edu/current-students/learning-resources/tutoring">
              Go To Tutoring
            </OutLink>
          </div>
          <div>
            <Paragraph>Interested in becoming a peer tutor?</Paragraph>
            <OutLink href="https://onedrive.live.com/?authkey=%21AMhYiA663kxEszo&id=6CB102D71D3775AF%21106&cid=6CB102D71D3775AF">
              Become a peer tutor
            </OutLink>
          </div>
        </div>
      </Tile>
      <Tile className="md:col-span-2" ariaLabel="Success Coaching">
        <div className="mb-2 flex items-center gap-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 576 512"
            className="h-7 w-7 min-w-[28px]"
          >
            <path
              className="fill-du-black stroke-du-black"
              d="M507.3 4.7c6.2 6.2 6.2 16.4 0 22.6L452.7 81.9c-9 9-21.2 14.1-33.9 14.1H304c-35.3 0-64 28.7-64 64v96c0 17.7 14.3 32 32 32s32-14.3 32-32V168c0-8.8 7.2-16 16-16s16 7.2 16 16v24H448c35.3 0 64 28.7 64 64c0 24.5-13.7 45.7-33.9 56.5c1.2 5 1.9 10.2 1.9 15.5c0 29.9-20.5 55-48.3 62.1C428.7 422.6 401.3 448 368 448H237.3c-4.2 0-8.3 1.7-11.3 4.7l-54.6 54.6c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6l54.6-54.6c9-9 21.2-14.1 33.9-14.1H368c17.7 0 32-14.3 32-32c0-1.8-.2-3.6-.4-5.4c-.8-4.7 .5-9.5 3.6-13.1s7.6-5.6 12.4-5.6l.4 0c17.7 0 32-14.3 32-32c0-6.1-1.7-11.7-4.6-16.6c-2.7-4.5-3.1-10-.9-14.8s6.5-8.2 11.6-9.2c14.8-2.8 25.9-15.8 25.9-31.4c0-17.7-14.3-32-32-32H336v32c0 35.3-28.7 64-64 64s-64-28.7-64-64V160c0-53 43-96 96-96H418.7c4.2 0 8.3-1.7 11.3-4.7L484.7 4.7c6.2-6.2 16.4-6.2 22.6 0zM125.6 190.3c9.2-24.5 27.6-43.5 50.4-53.8v37.1c-9.1 7.1-16.3 16.7-20.5 27.9l-26.1 69.6c-2.4 6.4-6.2 12.2-11 17.1L27.3 379.3c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6l91.1-91.1c1.6-1.6 2.9-3.6 3.7-5.7l26.1-69.6zm509.8-57.6c6.2 6.2 6.2 16.4 0 22.6L543.6 247c-1.2-12.5-4.7-24.4-10.2-35l79.3-79.3c6.2-6.2 16.4-6.2 22.6 0z"
            ></path>
          </svg>
          <Header className="!text-2xl">Success Coaching</Header>
        </div>
        <Paragraph className="mb-4">
          Our dedicated team is here to partner with you to achieve your goals.
        </Paragraph>
        <Paragraph className="mb-4">
          Overseas students may visit UMGC program coordinators and academic
          advisors in their local military education centers.
        </Paragraph>
        {getLinks(dutyStationAdvisorEmail)}
        <OutLink href="https://www.umgc.edu/current-students/student-life-and-support/advising">
          Go To Success Coaching
        </OutLink>
      </Tile>
      <Tile ariaLabel="Library">
        <div className="mb-2 flex items-center gap-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 576 512"
            className="h-7 w-7 min-w-[28px]"
          >
            <path
              className="fill-du-black stroke-du-black"
              d="M510.4 435.4l-107.7-399.9C396.9 14.07 377.5 0 356.4 0c-4.111 0-8.296 .5313-12.46 1.641l-61.82 16.48c-1.281 .3438-2.375 .9922-3.592 1.445C269.7 7.758 255.8 0 240 0h-64c-12.35 0-23.49 4.797-32 12.46C135.5 4.797 124.3 0 112 0h-64C21.49 0 0 21.49 0 48v416C0 490.5 21.49 512 48 512h64c12.35 0 23.49-4.797 32-12.46C152.5 507.2 163.7 512 176 512h64c26.51 0 48-21.49 48-48V224.7l67.8 251.9C361.6 497.9 380.9 512 402.1 512c4.111 0 8.293-.5313 12.46-1.641l61.82-16.48C502 487 517.2 460.8 510.4 435.4zM32 48C32 39.18 39.18 32 48 32h64C120.8 32 128 39.18 128 48l.0011 48L32 96V48zM32 128l96 .0014v255.1L32 384V128zM112 480h-64C39.18 480 32 472.8 32 464V416l96 0L128 464C128 472.8 120.8 480 112 480zM256 464c0 8.824-7.178 16-16 16h-64C167.2 480 160 472.8 160 464l.0011-47.1L256 416V464zM256 384l-95.1 .0002V128L256 128V384zM256 96L160 96L160 48C160 39.18 167.2 32 176 32h64C248.8 32 256 39.18 256 48V96zM288 101.6V50.29c.834-.3906 1.322-.9727 2.322-1.242l61.82-16.48C353.5 32.19 354.1 32 356.4 32c7.225 0 13.57 4.828 15.43 11.74l12.4 46.07l-92.71 24.73L288 101.6zM366 391.4l-66.26-246.1l92.71-24.73l66.26 246.1L366 391.4zM477.9 455.5c-1.23 2.133-4.133 5.934-9.729 7.43l-61.82 16.48C404.1 479.8 403.5 480 402.1 480c-7.227 0-13.57-4.828-15.43-11.74l-12.4-46.07l92.71-24.73l12.44 46.22C480.9 449.1 479.1 453.4 477.9 455.5z"
            ></path>
          </svg>
          <Header className="!text-2xl">Library</Header>
        </div>
        <Paragraph className="mb-4">
          UMGC's online library is available 24x7 to students around the globe.
          Find articles, e-books, and other research resources for your
          assignments and get help from a Librarian.
        </Paragraph>
        <OutLink href="https://libguides.umgc.edu/home">Go To Library</OutLink>
      </Tile>
      <Tile className="md:col-span-2" ariaLabel="Career Services">
        <div className="mb-2 flex items-center gap-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 576 512"
            className="h-7 w-7 min-w-[28px]"
          >
            <path
              className="fill-du-black stroke-du-black"
              d="M448 96h-64V48C384 21.53 362.5 0 336 0h-160C149.5 0 128 21.53 128 48V96H64C28.65 96 0 124.7 0 160v256c0 35.35 28.65 64 64 64h384c35.35 0 64-28.65 64-64V160C512 124.7 483.3 96 448 96zM160 48C160 39.17 167.2 32 176 32h160C344.8 32 352 39.17 352 48V96H160V48zM480 416c0 17.64-14.36 32-32 32H64c-17.64 0-32-14.36-32-32V288h144v64c0 8.844 7.156 16 16 16h128c8.844 0 16-7.156 16-16V288H480V416zM208 336V288h96v48H208zM480 256H32V160c0-17.64 14.36-32 32-32h384c17.64 0 32 14.36 32 32V256z"
            ></path>
          </svg>
          <Header className="!text-2xl">Career Services</Header>
        </div>
        <Paragraph className="mb-4">
          Career Services offers current students and alumni easy access to
          career-related support and resources.
        </Paragraph>
        <OutLink href="https://www.umgc.edu/current-students/career-services">
          Go To Career Services
        </OutLink>
      </Tile>
      <Tile ariaLabel="Accessibility Services">
        <div className="mb-2 flex items-center gap-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 576 512"
            className="h-7 w-7 min-w-[28px]"
          >
            <path
              className="fill-du-black stroke-du-black"
              d="M256 192c26.51 0 48-21.49 48-48S282.5 96 256 96S208 117.5 208 144S229.5 192 256 192zM256 128c8.822 0 16 7.178 16 16S264.8 160 256 160S240 152.8 240 144S247.2 128 256 128zM362.9 194.8L305.5 213.1c-31.94 10.66-67.06 10.66-99 0L149.1 194.8c-8.422-2.859-17.44 1.719-20.23 10.11s1.734 17.45 10.11 20.23l57.44 19.14C200.2 245.6 204.1 246.2 208 247.2v71.27l-15.69 78.41c-1.734 8.672 3.891 17.09 12.55 18.83c8.734 1.828 17.11-3.891 18.83-12.55L237.1 336h37.78l13.42 67.14C289.8 410.8 296.5 416 303.1 416c1.031 0 2.094-.0938 3.156-.3125c8.656-1.734 14.28-10.16 12.55-18.83L304 318.4V247.2c3.863-1.021 7.822-1.598 11.62-2.867l57.44-19.14c8.375-2.781 12.91-11.84 10.11-20.23S371.4 191.1 362.9 194.8zM272 304h-32V252.7C245.3 253.2 250.6 254 256 254s10.67-.834 16-1.289V304zM256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM256 480c-123.5 0-224-100.5-224-224s100.5-224 224-224s224 100.5 224 224S379.5 480 256 480z"
            ></path>
          </svg>
          <Header className="!text-2xl">Accessibility Services</Header>
        </div>
        <Paragraph className="mb-4">
          Find information on the accessibility accommodations process,
          documentation, rights, and self-advocacy.
        </Paragraph>
        <OutLink href="https://www.umgc.edu/current-students/student-life-and-support/accessibility-accommodations">
          Go To Accessibility Services
        </OutLink>
      </Tile>

      <Tile ariaLabel="GetSet Student Community" className="md:col-span-2">
        <div className="mb-2 flex items-center gap-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 576 512"
            className="h-7 min-w-[28px]"
            stroke="black"
            strokeWidth={"30px"}
            style={{ width: "3.1rem", marginLeft: "-15px" }}
          >
            <path
              className="fill-du-black stroke-du-black"
              d="M128 64a24 24 0 1 1 0 48 24 24 0 1 1 0-48zm0 80a56 56 0 1 0 0-112 56 56 0 1 0 0 112zM0 280c0 43.3 26.4 80.4 64 96v48c0 13.3 10.7 24 24 24h80c13.3 0 24-10.7 24-24V384c0-8.8-7.2-16-16-16s-16 7.2-16 16v32H96V208.4c2.6-.3 5.3-.4 8-.4h48c9.3 0 18.1 1.7 26.2 4.9c8.2 3.2 17.5-.9 20.7-9.1s-.9-17.5-9.1-20.7c-11.7-4.6-24.5-7.1-37.8-7.1H104C46.6 176 0 222.6 0 280zm64 59.9C44.7 327 32 304.9 32 280c0-25 12.7-47 32-59.9V339.9zM512 64a24 24 0 1 1 0 48 24 24 0 1 1 0-48zm0 80a56 56 0 1 0 0-112 56 56 0 1 0 0 112zM640 280c0-57.4-46.6-104-104-104H488c-13.3 0-26.1 2.5-37.8 7.1c-8.2 3.2-12.3 12.5-9.1 20.7s12.5 12.3 20.7 9.1c8.1-3.2 16.9-4.9 26.2-4.9h48c2.7 0 5.4 .1 8 .4V416H480V384c0-8.8-7.2-16-16-16s-16 7.2-16 16v40c0 13.3 10.7 24 24 24h80c13.3 0 24-10.7 24-24V376c37.6-15.7 64-52.7 64-96zm-64 59.9V220.1C595.3 233 608 255 608 280c0 24.9-12.7 47-32 59.9zM288 96a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zm96 0A64 64 0 1 0 256 96a64 64 0 1 0 128 0zm-80 96c-57.4 0-104 46.6-104 104c0 40.1 22.7 74.9 56 92.3V456c0 13.3 10.7 24 24 24h80c13.3 0 24-10.7 24-24V388.3c33.3-17.3 56-52.1 56-92.3c0-57.4-46.6-104-104-104H304zm-48 50.3V349.7c-14.7-13.2-24-32.4-24-53.7c0-21.3 9.3-40.5 24-53.7zM288 448V225.8c5.1-1.2 10.5-1.8 16-1.8h32c5.5 0 10.9 .6 16 1.8V448H288zm96-98.3V242.3c14.7 13.2 24 32.3 24 53.7c0 21.3-9.3 40.5-24 53.7z"
            />
          </svg>
          <Header className="!text-2xl "> GetSet Student Community</Header>
        </div>
        <Paragraph className="mb-4">
          GetSet is your UMGC student-only space to connect with classmates,
          share advice, and support one another.
        </Paragraph>
        <OutLink href=" https://umgc.getset.com/login">
          Go to GetSet Student Community
        </OutLink>
      </Tile>

      <Tile ariaLabel="Wellness Resources">
        <div className="mb-2 flex items-center gap-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 576 512"
            className="h-7 w-7 min-w-[28px]"
          >
            <path
              className="fill-du-black stroke-du-black"
              d="M568.3 192c-22.62 0-92 4.335-158.9 42.33c-19.38-81.49-60.38-153.9-112.5-199.2C294.5 32.1 291.2 32 288 32S281.6 32.1 279.1 35.12C227 80.5 185.1 152.9 166.6 234.5C99.75 196.4 30.38 192.2 7.75 192C3.375 192 0 195.5 0 199.9c.25 27.1 7.125 126.2 88.75 199.3c58.25 56.75 131.1 74.94 177 80.57c0 .375 44.5 .375 44.5 0c45.88-5.625 118.8-23.82 177-80.57C568.9 326 575.8 227.9 576 199.9C576 195.5 572.6 192 568.3 192zM110.1 375.5C54.88 325.1 38.5 263.2 33.75 225.5c40.87 4.125 111 19.37 166.5 73.49c25.38 22.75 44.38 49.1 58.13 83.24C266.9 402.8 272 423.6 272 448.1C229.3 446.1 157.4 421.5 110.1 375.5zM221.6 275c-8.5-8.249-17.5-15.29-26.5-22.04C210.2 181.4 243.9 114.9 288 70.62c44.25 44.25 77.75 110.7 92.88 182.4c-9 6.75-18 13.79-26.5 22.04C321.2 304.6 301 338.4 288 369.9C275 338.4 254.8 304.6 221.6 275zM464.9 376.4C420.2 419.8 348.8 446.8 304 448.1c0-24.37 5-45.12 13.62-65.87c13.75-33.37 32.75-60.5 59.13-84.24c54.75-53.37 124.7-68.37 165.4-72.5C537.5 263.2 520.9 326.1 464.9 376.4z"
            ></path>
          </svg>
          <Header className="!text-2xl">Wellness Resources</Header>
        </div>
        <Paragraph className="mb-4">
          Learn about the comprehensive mental health and wellness resources
          available to students.
        </Paragraph>
        <OutLink href="https://www.umgc.edu/current-students/student-life-and-support/wellness-resources.html">
          Go to Wellness Resources
        </OutLink>
      </Tile>

      <Tile className="sm:col-span-2 md:col-span-3" ariaLabel="Help Center">
        <Header className="mb-4">Help Center</Header>
        <Paragraph className="mb-4">
          Find answers to questions on topics such as admission, course
          registration, and technical support, or contact us by phone, chat or
          e-mail.
        </Paragraph>
        <OutLink href="https://www.umgc.edu/help#">Go To Help Center</OutLink>
      </Tile>
    </PageWrapper>
  );
};

angularize(StudentSupport, "studentSupportReact", angular.module("duApp"), {
  dutyStationAdvisorEmail: "<",
});

export default StudentSupport;
