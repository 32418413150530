import React, { useEffect, useState } from "react";
import { angularize } from "react-in-angularjs";

const RadioGroup: React.FC = () => {
  const [selectedValue, setSelectedValue] = useState("");

  const handleClickEvent = (event: React.MouseEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setSelectedValue(value);
    dispatchCustomEvent(value);
  };

  const keyDownEvent = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "ArrowLeft" || event.key === "ArrowRight") {
      event.preventDefault();
      // Toggle between "Yes" and "No" on arrow keys
      const newValue = selectedValue === "1" ? "0" : "1";
      setSelectedValue(newValue);
      dispatchCustomEvent(newValue);
    } else if (event.key === "Enter") {
      setSelectedValue(event.currentTarget.value);
      dispatchCustomEvent(event.currentTarget.value);
    }
  };

  const dispatchCustomEvent = (value: string) => {
    const customEvent = new CustomEvent("selecttedValueUpdate", {
      detail: value,
    });
    window.dispatchEvent(customEvent);
  };

  return (
    <div>
      <div className="mb-[0.125rem] block min-h-[1.5rem] pl-[0.56rem]">
        <div>
          <label className="text-sm font-bold">
            Are you currently serving or have you or your sponsor served in the
            US Armed Forces?
          </label>
        </div>
        <div
          role="radiogroup"
          aria-label="Are you currently serving or have you or your sponsor served in the US Armed Forces?"
        >
          <div
            className="inline-flex items-center"
            style={{ fontWeight: "bold" }}
          >
            <input
              type="radio"
              name="radioGroup"
              value="1"
              className="border-3 mr-2 h-5 w-5 rounded-full border-black"
              checked={selectedValue === "1"}
              onClick={handleClickEvent}
              onKeyDown={keyDownEvent}
              tabIndex={0}
              aria-labelledby="demo1"
            />
            <span className="mr-4" id="demo1">
              Yes
            </span>
          </div>
          <div
            className="inline-flex items-center"
            style={{ fontWeight: "bold" }}
          >
            <input
              type="radio"
              name="radioGroup"
              value="0"
              checked={selectedValue === "0"}
              className="border-3 mr-2 h-5 w-5 rounded-full border-black"
              onClick={handleClickEvent}
              onKeyDown={keyDownEvent}
              tabIndex={0}
              aria-labelledby="demo2"
            />
            <span id="demo2">No</span>
          </div>
        </div>
      </div>
    </div>
  );
};

angularize(RadioGroup, "radioReact", angular.module("duApp"), {});
export default RadioGroup;
