import clsx from "clsx";
import Paragraph from "components/Paragraph";
import CheckCircle from "icons/CheckCircle";
import React from "react";

export interface AlertType {
  type: "error" | "success";
  message: string;
  show: boolean;
}

export interface AlertProps extends AlertType {
  setShow: (show: boolean) => void;
}

const Alert: React.FC<AlertProps> = ({ type, message, show, setShow }) => {
  return (
    <div
      className={clsx(
        "mb-6 flex items-stretch justify-between bg-white shadow-lg",
        show ? "block" : "hidden"
      )}
    >
      <div
        className={clsx(
          "flex w-[32px] justify-center p-4",
          type === "success" ? "bg-[#149014]" : "bg-[#C2172D]"
        )}
      >
        <CheckCircle className="w-[24px]" />
      </div>
      <Paragraph className="flex w-full items-center justify-between p-4 font-medium">
        <span className="font-bold">{message}</span>
        <button
          className="border-0 text-2xl"
          onClick={() => {
            setShow(false);
          }}
        >
          &times;
        </button>
      </Paragraph>
    </div>
  );
};

export default Alert;
