import { Transition } from "@headlessui/react";
import * as Dialog from "@radix-ui/react-dialog";
import clsx from "clsx";
import React, { Fragment } from "react";

interface ModalWrapperProps {
  show: boolean;
  className?: string;
  children: React.ReactNode;
  ariaLabelledby?: string;
}

const ModalWrapper: React.FC<ModalWrapperProps> = ({
  show,
  className,
  children,
  ariaLabelledby,
}) => {
  return (
    <Dialog.Portal
      forceMount
      container={document.getElementById("react-portal")}
    >
      <Transition.Root show={show}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay
            forceMount
            className="fixed inset-0 z-[99] bg-black/50"
          />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Dialog.Content
            aria-labelledby={ariaLabelledby}
            onOpenAutoFocus={(e) => {
              e.preventDefault();
            }}
            forceMount
            style={{
              transform: "translate(-50%, -50%)",
            }}
            className={clsx(
              "fixed left-[50%] top-[50%] z-[100] max-h-[75vh] overflow-scroll overflow-x-auto overflow-y-auto bg-white px-[30px] py-[20px] text-sm",
              className
            )}
          >
            {children}
          </Dialog.Content>
        </Transition.Child>
      </Transition.Root>
    </Dialog.Portal>
  );
};

export default ModalWrapper;
