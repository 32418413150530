import React from "react";

interface PenProps extends React.SVGProps<SVGSVGElement> {
  fillColor?: string;
}

const Pen: React.FC<PenProps> = ({ fillColor, ...props }) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="-10 0 533 512"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.7998 371.2l-12.5996 114.1c-1.7002 15.2998 11.2002 28.2002 26.5 26.5l114.2 -12.7002l352.399 -352.399c25 -25 25 -65.5 0 -90.5l-37.5 -37.5c-25 -25 -65.5 -25 -90.5 0zM126.1 468.6l-93.0996 10.3008l10.2998 -93.1006l271.9 -271.899l82.7002 82.6992z
        M470.6 124.1l-49.8994 49.9004l-82.7002 -82.7002l49.9004 -49.8994c12.5 -12.5 32.6992 -12.5 45.2998 0l37.5 37.5c12.3994 12.3994 12.3994 32.6992 -0.100586 45.1992z"
        fill={fillColor ? fillColor : "#ffffff"}
      />
    </svg>
  );
};

export default Pen;
